<script setup>
import { useApp } from "~/composables/useApp";

const { appVars } = useApp();
</script>
<template>
  <div
    class="app-header px-2"
    :class="{
      hidden: !appVars.isHeaderVisible,
    }"
  >
    <v-btn @click="appVars.appMenu = true" icon="sm" class="icon-size">
      <div class="menu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </v-btn>

    <div class="logo-box" @click="navigateTo('/')">
      <img class="logo" src="/icons/logo.svg" alt="logo" />
    </div>

    <div style="width: 40px"></div>
    <!-- <v-btn @click="" icon="sm" class="icon-size">
      <div class="background">
        <img src="/icons/user.svg" />
      </div>
    </v-btn> -->
  </div>
</template>
<style scoped>
.app-header {
  width: 100%;
  height: var(--header-height);
  background-color: var(--root-white);
  display: flex;
  align-items: center;
  max-width: var(--width-mobile);
  font-size: 24pt;

  max-width: var(--width-mobile);
  box-sizing: border-box;

  justify-content: space-between;
  flex-shrink: 0;
  z-index: 5;
  transition: all 0.2s ease;

  position: relative;
  top: 0;
}
.app-header.hidden {
  position: fixed;
  /* transform: translate3d(0, -100%, 0);
  height: 0px; */
  /* display: none; */
  margin-top: -50px;
}

.icon {
  width: 40px;
}
.logo-box {
  display: flex;
}
.icon-size {
  color: #292937;
  width: 40px;
  height: 40px;
}
.icon-size.fix {
  width: 30px;
  height: 100%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23px;
  height: 15px;
}
.menu span {
  background: #292937;
  height: 3px;
  border-radius: 4px;
  width: 100%;
}

.menu span:nth-child(2n + 1) {
  width: 70%;
  transition: all 0.3s ease;
}
.menu span:nth-child(1) {
  margin-right: auto;
}
.menu span:nth-child(3) {
  margin-left: auto;
}

.menu:hover span:nth-child(2n + 1) {
  width: 100%;
}

.background {
  background-color: #292937;
  border-radius: 8px;
  min-width: 31px;
  min-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background img {
  width: 11px;
  height: 13px;
}

.logo {
  width: 130px;
  height: 34px;
}
</style>
